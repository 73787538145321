import React from "react";
type SidebarContext = { sidebarToggle: any; toggleSidebar: () => void };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = React.createContext<SidebarContext>({} as SidebarContext);

type Props = {
  children: React.ReactNode;
};

export const SidebarProvider: React.FC<Props> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = React.useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <SidebarContext.Provider value={{ sidebarToggle, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
