import jwt_decode from "jwt-decode";

export const filterModules = (listModules: any[], moduleId: string) =>
  listModules.filter((data: any) => data.moduleId === moduleId);

export const filterAccess = (routes: any) => {
  let listRoutes = [
    {
      path: routes.path,
      element: routes.element,
      children: [] as any,
    },
  ];
  const getToken = localStorage.getItem(process.env.REACT_APP_AUTH as string);
  if (getToken === null) return [];

  const splitToken = getToken.split(" ");
  const { listModuleAccess }: any = jwt_decode(splitToken[1]);

  const listModules = routes.children;
  const routeLength = listModules.length;
  for (let i = 0; i < routeLength; i++) {
    const element = listModules[i];
    const findModule = listModuleAccess.find((prev: any) => prev.moduleId === element.id);

    if (element.id === "allow") {
      listRoutes[0].children.push({
        path: element.path,
        element: element.element,
      });
    }

    if (findModule !== undefined && findModule.canRead === 1) {
      listRoutes[0].children.push({
        path: element.path,
        element: element.element,
      });
    }
  }

  return listRoutes;
};
