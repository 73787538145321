import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { store } from "./redux/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { SidebarProvider } from "./contexts/SidebarContext";

import "nprogress/nprogress.css";
import "./styles/index.scss";

const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <SidebarProvider>
            <App />
          </SidebarProvider>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  </HelmetProvider>
);

serviceWorker.unregister();
