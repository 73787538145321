import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

import sentrisLogo from "src/images/logo.png";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
    color: ${theme.palette.text.primary};
    padding: 0px;
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to="/dashboards">
      <img src={sentrisLogo} width={150} alt="logo" />
    </LogoWrapper>
  );
}

export default Logo;
