import { ReactElement, KeyboardEvent, MouseEventHandler, CSSProperties } from "react";
import {
  Controller,
  Control,
  FieldValues,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
} from "react-hook-form";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
} from "@mui/material";
import { forwardRef } from "react";
import NumberFormat from "react-number-format";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface renderProp {
  field: ControllerRenderProps<FieldValues, string>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}

export const controllerComponent = (
  inputName: string,
  control: Control<FieldValues, any>,
  defaultValue: any,
  func: ({ field }: renderProp) => ReactElement,
  rules?: object
) => {
  return (
    <Controller
      control={control}
      name={inputName}
      render={func}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
};

export const errorMessageDsplay = (text: string) => (
  <p
    style={{
      color: "red",
      fontSize: 12,
      marginTop: 5,
      marginLeft: 5,
      marginBottom: 0,
      fontWeight: "bold",
    }}
  >
    {text}
  </p>
);

type OutlineInputProps = {
  control: Control<FieldValues, any>;
  inputName: string;
  label: string;
  rules?: object;
  defaultValue?: any;
  inputType?: string;
  onKeyDown?: (e: KeyboardEvent) => void;
  size?: "small" | "medium" | undefined;
  placeholder?: string;
  disabled?: boolean;
  multiline?: boolean;
  minRows?: number;
  inputStyle?: object;
  inputContainerStyle?: CSSProperties;
  inputProps?:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | undefined;
};

export const OutlineInput = ({
  control,
  inputName,
  label,
  rules,
  defaultValue = "",
  inputType = "text",
  onKeyDown,
  size = "medium",
  placeholder,
  multiline = false,
  minRows = 1,
  disabled = false,
  inputStyle,
  inputContainerStyle,
  inputProps,
}: OutlineInputProps) =>
  controllerComponent(
    inputName,
    control,
    defaultValue,
    ({ field, fieldState: { error } }) => (
      <div style={{ marginBottom: 12, ...inputContainerStyle }}>
        <TextField
          {...field}
          size={size}
          placeholder={placeholder}
          error={error !== undefined}
          id={inputName}
          label={label}
          fullWidth
          type={inputType}
          sx={inputStyle}
          disabled={disabled}
          multiline={multiline}
          minRows={minRows}
          onKeyDown={onKeyDown}
          InputProps={inputProps}
        />
        {error !== undefined && errorMessageDsplay(error.message!)}
      </div>
    ),
    rules
  );

type OutlineInputPassProps = {
  control: Control<FieldValues, any>;
  inputName: string;
  label: string;
  showPass: boolean;
  onTap: () => void;
  rules: object;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  placeholder?: string;
  inputStyle?: object;
  inputContainerStyle?: CSSProperties;
};

export const OutlineInputPassword = ({
  control,
  inputName,
  label,
  showPass,
  onTap,
  rules,
  size = "medium",
  disabled = false,
  placeholder,
  inputStyle,
  inputContainerStyle = { marginBottom: 10 },
}: OutlineInputPassProps) =>
  controllerComponent(
    inputName,
    control,
    "",
    ({ field, fieldState: { error } }) => (
      <div style={inputContainerStyle}>
        <FormControl
          error={error !== undefined}
          variant="outlined"
          fullWidth
          sx={inputStyle}
          size={size}
          disabled={disabled}
        >
          <InputLabel htmlFor={inputName}>{label}</InputLabel>
          <OutlinedInput
            {...field}
            id={inputName}
            type={showPass ? "text" : "password"}
            placeholder={placeholder}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onTap} edge="end" disabled={disabled}>
                  {showPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
          />
        </FormControl>
        {error !== undefined && errorMessageDsplay(error.message!)}
      </div>
    ),
    rules
  );

type OutlineSelectInputProps = {
  control: Control<FieldValues, any>;
  inputName: string;
  label: string;
  displayValue: false | JSX.Element[];
  displayMessage: JSX.Element;
  rules?: object;
  onTap?: MouseEventHandler<HTMLDivElement> | undefined;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  placeholder?: string;
  inputStyle?: object;
  inputContainerStyle?: CSSProperties;
};

export const OutlineSelectInput = ({
  control,
  inputName,
  label,
  displayValue,
  displayMessage,
  rules,
  onTap,
  size = "medium",
  disabled = false,
  inputStyle = { mb: 0 },
  inputContainerStyle = { marginBottom: 10 },
}: OutlineSelectInputProps) => {
  return (
    <>
      {controllerComponent(
        inputName,
        control,
        "",
        ({ field, fieldState: { error } }) => (
          <div style={inputContainerStyle}>
            <FormControl
              fullWidth
              sx={inputStyle}
              error={error !== undefined}
              disabled={disabled}
              size={size}
            >
              <InputLabel id={inputName}>{label}</InputLabel>
              <Select {...field} labelId={label} id={inputName} label={label} onClick={onTap}>
                {displayMessage}
                {displayValue}
              </Select>
            </FormControl>
            {error !== undefined && errorMessageDsplay(error.message!)}
          </div>
        ),
        rules
      )}
    </>
  );
};

interface NumberProps {
  onChange: (values: number) => void;
  name: string;
}

export const NumberFormatCustom = forwardRef<any, NumberProps>(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => onChange(values.floatValue === undefined ? 0 : values.floatValue)}
      thousandSeparator
      isNumericString
      allowNegative={false}
      prefix="Rp "
    />
  );
});
