import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listEmployees: any[];
  listRole: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
  isShowOldPassword: boolean;
  isShowPassword: boolean;
  isShowConfPassword: boolean;
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: State = {
  listEmployees: [],
  listRole: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
  isShowOldPassword: false,
  isShowPassword: false,
  isShowConfPassword: false,
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const userSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    updatedUser: (state, { payload }) => {
      const [findData] = state.listEmployees.filter((data: any) => data.id === payload);
      if (Object.keys(findData).length > 0) {
        state.showModal = !state.showModal;
        state.id = findData.id;
        state.editMode = true;
        state.editValue = findData;
      }
    },
    handleChange: (state, { payload }) => {
      state.currentPage = payload;
    },
    handleShowPass: (state, { payload }) => {
      if (payload === "oldPassword") {
        state.isShowOldPassword = !state.isShowOldPassword;
      }

      if (payload === "password") {
        state.isShowPassword = !state.isShowPassword;
      }

      if (payload === "confPassword") {
        state.isShowConfPassword = !state.isShowConfPassword;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        const { data, roleData, count } = payload;
        state.isLoading = false;
        state.listRole = roleData;
        state.listEmployees = data;
        state.count = count;
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createUsers.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createUsers.fulfilled, (state) => {
        state.isDisabled = false;
      })
      .addCase(createUsers.rejected, (state) => {
        state.isDisabled = false;
      })

      .addCase(editUser.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editUser.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const index = state.listEmployees.findIndex((prev: any) => prev.id === data.id);
        state.isDisabled = false;
        state.listEmployees[index] = data;
      })
      .addCase(editUser.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(changePassword.pending, (state) => {
        state.isDisabled = true;
        state.isShowOldPassword = false;
        state.isShowPassword = false;
        state.isShowConfPassword = false;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.isDisabled = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.isDisabled = false;
      });
  },
});
export const { showPopupModal, handleShowPass, updatedUser, handleChange } = userSlice.actions;
export default userSlice.reducer;

export const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/users?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const createUsers = createAsyncThunk("createUsers", async (data: any, _) => {
  try {
    const response = await axiosInstance.post("/users", data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editUser = createAsyncThunk("editUser", async (data: any, _) => {
  try {
    const response = await axiosInstance.put("/users", data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteUser = createAsyncThunk("deleteUser", async (id: string, _) => {
  try {
    const response = await axiosInstance.delete(`/users/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const changePassword = createAsyncThunk("changePassword", async (value: any) => {
  try {
    const response = await axiosInstance.put(`/change-password`, value);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
