import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Box, Card, CardContent, CardActions } from "@mui/material";

import Layout from "src/components/Layout";
import { routePath } from "src/layouts/SidebarLayout/Sidebar/SidebarMenu/items";
import { SwalAlertModal } from "src/components/SweetAlert";
import { OutlineInput, OutlineInputPassword } from "../../components/OutlineInput";
import logoUrl from "../../images/logo.png";

// redux
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { showPassword, login } from "src/redux/auth";

const Signin = () => {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm();
  const dispatch = useAppDispatch();
  const { isLoading, isShowPassword, token } = useAppSelector((state) => state.auth);

  const effect = () => {
    if (token !== null) navigate(routePath.DASHBOARD.pathName, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onSubmit = (data: any) => {
    const value = {
      account: data.account.trim(),
      password: data.password.trim(),
    };

    dispatch(login(value))
      .unwrap()
      .then((_) => {
        navigate(routePath.DASHBOARD.pathName, { replace: true });
      })
      .catch((err) => SwalAlertModal("Gagal!", err.message, "error"));
  };

  return (
    <Layout title="Sign in" effectCallback={effect}>
      <Box sx={{ display: "flex", justifyContent: "center", boxShadow: 3, mt: 20 }}>
        <Card sx={{ width: 430 }}>
          <div style={{ textAlign: "center", paddingTop: 20, paddingBottom: 10 }}>
            <img src={logoUrl} alt="sentris logo" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <OutlineInput
                control={control}
                inputName="account"
                label="Username or Email"
                rules={{ required: { value: true, message: "Required!" } }}
                placeholder="Username or Email"
                disabled={isLoading}
              />
              <OutlineInputPassword
                control={control}
                inputName="password"
                label="Password"
                showPass={isShowPassword}
                onTap={() => dispatch(showPassword())}
                rules={{ required: { value: true, message: "Required!" } }}
                disabled={isLoading}
              />
            </CardContent>
            <CardActions sx={{ justifyContent: "center", flexDirection: "column" }}>
              <Button type="submit" variant="contained" disabled={isLoading}>
                Login
              </Button>
            </CardActions>
          </form>
        </Card>
      </Box>
    </Layout>
  );
};

export default Signin;
