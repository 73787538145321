import { MenuItem } from "./index";

export const routePath = {
  // AUTH
  SIGNIN: { id: "", pathName: "/signin" },

  //DASHBOARD
  DASHBOARD: { id: "", pathName: "/dashboard" },

  // MANAGEMENT TEAM
  ROLES: { id: "047cd028-dfb2-4d25-a6a2-34fa6a809a6a", pathName: "/management-teams/roles" },
  PERMISSION: {
    id: "14df527b-bd2c-4f4b-b957-4610f4143f41",
    pathName: "/management-teams/permission",
  },
  ROLE_PERMISSION: {
    id: "14df527b-bd2c-4f4b-b957-4610f4143f41",
    pathName: "/management-teams/permission/role-permission/:id",
  },
  EMPLOYEES: { id: "cf555d97-44a9-48cb-9b99-6191461e1b50", pathName: "/employees" },

  //  MANAGEMENT MASTER DATA
  CATEGORIES_PRODUCT: {
    id: "0ea33649-4e4e-424c-9228-76042232b470",
    pathName: "/management-master-data/categories-product",
  },
  UPLOAD_CATEGORIES: {
    id: "0ea33649-4e4e-424c-9228-76042232b470",
    pathName: "/management-master-data/categories-product/upload-categories",
  },
  UNITS: { id: "bb93f666-4284-11ef-ac12-1f77f56a33c0", pathName: "/management-master-data/units" },
  UPLOAD_UNITS: {
    id: "bb93f666-4284-11ef-ac12-1f77f56a33c0",
    pathName: "/management-master-data/units/upload-units",
  },
  COLORS: {
    id: "bb940d7c-4284-11ef-ac12-1f77f56a33c0",
    pathName: "/management-master-data/colors",
  },
  UPLOAD_COLORS: {
    id: "bb940d7c-4284-11ef-ac12-1f77f56a33c0",
    pathName: "/management-master-data/colors/upload-colors",
  },
  MODELS: {
    id: "bb941150-4284-11ef-ac12-1f77f56a33c0",
    pathName: "/management-master-data/models",
  },
  UPLOAD_MODELS: {
    id: "bb941150-4284-11ef-ac12-1f77f56a33c0",
    pathName: "/management-master-data/models/upload-models",
  },
  BRANDS: {
    id: "bb94134e-4284-11ef-ac12-1f77f56a33c0",
    pathName: "/management-master-data/brands",
  },
  UPLOAD_BRANDS: {
    id: "bb94134e-4284-11ef-ac12-1f77f56a33c0",
    pathName: "/management-master-data/brands/upload-brands",
  },

  // MANAGEMENT CLIENT
  BANNER: {
    id: "dfd22017-c177-45ef-8737-790c4865bf78",
    pathName: "/management-client/banner",
  },
  BANNER_FORM: {
    id: "dfd22017-c177-45ef-8737-790c4865bf78",
    pathName: "/management-client/banner-form",
  },
  // backup banner
  CLIENT_BANNER: {
    id: "dfd22017-c177-45ef-8737-790c4865bf78",
    pathName: "/management-client/banner/:id",
  },
  CLIENT_ROLES: {
    id: "2257d532-7cf4-43b0-93d0-2a161a1c66f4",
    pathName: "/management-client/roles",
  },
  CLIENT_PERMISSION: {
    id: "8ca18312-5872-4a17-8136-49133c516646",
    pathName: "/management-client/permission",
  },
  CLIENT_ROLE_PERMISSION: {
    id: "8ca18312-5872-4a17-8136-49133c516646",
    pathName: "/management-client/permission/role-permission/:id",
  },
  CLIENT: { id: "e934056b-0491-4813-8066-3858bdf4995f", pathName: "/management-client/clients" },
  CLIENT_CATEGORIES: {
    id: "364064f0-45da-11ef-90e1-3c8d12b1443e",
    pathName: "/management-client/client-categories",
  },
  SERVICE: { id: "0be7127b-d91d-4371-b540-029bdbe3a868", pathName: "/management-client/service" },

  // CHANGE PASS
  CHANGE_PASSWORD: { id: "", pathName: "/change-password" },

  // 404
  NOT_FOUND: { id: "", pathName: "/not-found" },
};

const menuItems: MenuItem[] = [
  {
    name: "Dashboard",
    link: routePath.DASHBOARD.pathName,
  },
  {
    name: "Management Teams",
    link: "/management-teams",
    items: [
      {
        id: routePath.ROLES.id,
        name: "Roles",
        link: routePath.ROLES.pathName,
      },
      {
        id: routePath.PERMISSION.id,
        name: "Permission",
        link: routePath.PERMISSION.pathName,
      },
      {
        id: routePath.EMPLOYEES.id,
        name: "Teams",
        link: routePath.EMPLOYEES.pathName,
      },
    ],
  },
  {
    name: "Management Client",
    link: "/management-client",
    items: [
      {
        id: routePath.BANNER.id,
        name: "Banner",
        link: routePath.BANNER.pathName,
      },
      {
        id: routePath.CLIENT_ROLES.id,
        name: "Roles",
        link: routePath.CLIENT_ROLES.pathName,
      },
      {
        id: routePath.CLIENT_PERMISSION.id,
        name: "Permission",
        link: routePath.CLIENT_PERMISSION.pathName,
      },
      {
        id: routePath.CLIENT.id,
        name: "Clients",
        link: routePath.CLIENT.pathName,
      },
      {
        id: routePath.CLIENT_CATEGORIES.id,
        name: "Categories",
        link: routePath.CLIENT_CATEGORIES.pathName,
      },
      {
        id: routePath.SERVICE.id,
        name: "Services",
        link: routePath.SERVICE.pathName,
      },
    ],
  },
  {
    name: "Management Master Data",
    link: "/management-master-data",
    items: [
      {
        id: routePath.CATEGORIES_PRODUCT.id,
        name: "Categories Product",
        link: routePath.CATEGORIES_PRODUCT.pathName,
      },
      {
        id: routePath.UNITS.id,
        name: "UoM",
        link: routePath.UNITS.pathName,
      },
      {
        id: routePath.COLORS.id,
        name: "Colors",
        link: routePath.COLORS.pathName,
      },
      {
        id: routePath.BRANDS.id,
        name: "Brands",
        link: routePath.BRANDS.pathName,
      },
      {
        id: routePath.MODELS.id,
        name: "Models",
        link: routePath.MODELS.pathName,
      },
    ],
  },
  {
    name: "Change Password",
    link: routePath.CHANGE_PASSWORD.pathName,
  },
];

export default menuItems;
